import React from 'react';
import { useDispatch } from "react-redux";
import useCopyToClipboard from '../components/hook/CopyToClibboard';
import { BSC_EXPLORER } from '../../_configs';
import { useActiveWeb3React } from '../../hook';
import useAuth from '../../hook/useAuth';
import { ACTION_CONST } from '../../constants';

const WalletModal = () => {
    const dispatch = useDispatch();
    const [copied, setCopied] = useCopyToClipboard(1000);
    const { logout } = useAuth();
    const { account, chainId} = useActiveWeb3React()
    const handleLogout = () => {
        logout();
        localStorage.clear();
        dispatch({
            type: ACTION_CONST.LOG_OUT_WALLET_SUCCESS
        })
        window.location.reload();
    }

    return (
        <>
            <div className="modal fade " id="walletModal" tabIndex="-1" aria-labelledby="walletModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="walletModalLabel">Your wallet</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3 text-center">
                                <h5 style={{ fontSize: '20px', wordBreak: 'break-word' }} className="mb-3"><b>{account}</b></h5>
                                <a href={`${BSC_EXPLORER[chainId]}/address/${account}`} target="_blank" className="d-inline-flex align-items-center me-4" style={{ textDecoration: 'none' }}><span className="me-1">View on BscScan</span><i className="mdi mdi-open-in-new"></i></a>
                                <a className="d-inline-flex align-items-center" href="#" onClick={() => setCopied(account)} style={{ textDecoration: 'none' }}>
                                    <span className="me-1">Copy Address</span>{!copied ? <i className="mdi mdi-content-copy"></i> : <i className="mdi mdi-check"></i>}
                                </a>
                            </div>
                            <div className="text-center mt-4">
                                <button type="button" className="btn-pd mx-auto" data-bs-dismiss="modal" onClick={handleLogout}>
                                    <span>Logout</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WalletModal;


