import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import * as $ from 'jquery';

const PrivacyPolicyPage = () => {
    const handleScrollTop = (e) => {
        e.preventDefault();
        $('html,body').animate({
            scrollTop: $("#nav").offset().top - 100
        }, 'slow');
    }

    const handleScrollDiv = (e, sectId) => {
        e.preventDefault();
        $('html,body').animate({
            scrollTop: $(sectId).offset().top - 100
        }, 'slow');
    }

    useEffect(() => {
        $('body').addClass('no-bg');
        return () => {
            $('body').removeClass('no-bg');
        }
    }, [])

    return <>
        <div className="term-page">
            <Container>
                <div>
                    <div className="term-page-header text-center mb-5">
                        <h1 className="pd-title-section mb-1"><span>METAPAD LIMITED</span></h1>
                        <h4 className="mb-3">Privacy Policy of <a href="https://metavpad.com/">https://metavpad.com/</a></h4>
                        <p id="_ftn1"><strong>Last revised Date 15</strong><sup>th</sup><strong> December 2021</strong> <a className="text-white" href="#" onClick={(e)=>handleScrollDiv(e, '#_ftn0')}>[1]</a></p>
                    </div>
                    <div>
                        <h3>
                            <strong>Introduction</strong>
                        </h3>
                        <p>
                            This Privacy Policy describes how MetaPad Limited (herein referred to as “
                            <strong>METAVPAD</strong>”, “<strong>we</strong>”, “<strong>our</strong>”,
                            and “<strong>us</strong>”) collects, uses, processes, discloses, shares,
                            transfers, and protects the information you provide on{" "}
                            <a href="https://pulsepad.io/">https://metavpad.com/</a> (“
                            <strong>Website</strong>”) or on <strong>METAVPAD Platform</strong>. This
                            Privacy Policy constitutes a part of the Terms and Conditions of the Website
                            ("<strong>T&amp;Cs</strong>"). The terms used in this Privacy Policy will
                            share the same definition as those stipulated in the T&amp;Cs except for
                            others indicated herein.
                        </p>
                        <p>
                            We respect and value your privacy as well as the integrity of the
                            information you have provided and/or permitted us to collect. Thus, we
                            endeavour to follow industry standards to ensure the security and
                            confidentiality of your Personal Information when you use our Services or
                            access METAVPAD Platform.
                        </p>
                        <p>
                            When accessing, connecting, using, clicking on, or engaging in any Services
                            provided on Platform, you are strongly advised to carefully read,
                            acknowledge, understand, and accept all terms stipulated in this Privacy
                            Policy. We will not use your information, in any shape or form, for any
                            other purpose that has not been indicated in this Privacy Policy or the
                            T&amp;Cs without providing you prior notification or obtaining consent from
                            you.
                        </p>
                        <p>
                            This Privacy Policy has incorporated several provisions from the General
                            Data Protection Regulation ("<strong>GDPR</strong>"), specifically following
                            its Personal Information Processing rules within the European Economic Area
                            (“<strong>EEA</strong>”).
                        </p>
                        <p>
                            This Privacy Policy will inform you about the following matters among
                            several others:
                        </p>
                        <ul id="nav">
                            <li><a href="#" onClick={(e) => handleScrollDiv(e, "#sect1")}>Acceptance of the Privacy Policy</a></li>
                            <li><a href="#" onClick={(e) => handleScrollDiv(e, "#sect2")}>Our Relationship with you</a></li>
                            <li><a href="#" onClick={(e) => handleScrollDiv(e, "#sect3")}>Personal Information that we collect</a></li>
                            <li><a href="#" onClick={(e) => handleScrollDiv(e, "#sect4")}>How we collect Personal Information</a></li>
                            <li><a href="#" onClick={(e) => handleScrollDiv(e, "#sect5")}>How we use Personal Information</a></li>
                            <li><a href="#" onClick={(e) => handleScrollDiv(e, "#sect6")}>How we protect Personal Information</a></li>
                            <li><a href="#" onClick={(e) => handleScrollDiv(e, "#sect7")}>How long we retain Personal Information</a></li>
                            <li><a href="#" onClick={(e) => handleScrollDiv(e, "#sect8")}>Cookies</a></li>
                            <li><a href="#" onClick={(e) => handleScrollDiv(e, "#sect9")}>Sharing your Personal Information</a></li>
                            <li><a href="#" onClick={(e) => handleScrollDiv(e, "#sect10")}>Third-Party Collection of Personal Information</a></li>
                            <li><a href="#" onClick={(e) => handleScrollDiv(e, "#sect11")}>Designated Countries and Your Legal Rights</a></li>
                            <li><a href="#" onClick={(e) => handleScrollDiv(e, "#sect11_1")}>Rights of the residents of the European Economic Area (EEA)</a></li>
                            <li><a href="#" onClick={(e) => handleScrollDiv(e, "#sect12")}>Cross-Border Transfer of Personal Information</a></li>
                            <li><a href="#" onClick={(e) => handleScrollDiv(e, "#sect13")}>Limitations</a></li>
                            <li><a href="#" onClick={(e) => handleScrollDiv(e, "#sect14")}>Updates or amendments to the Privacy Policy</a></li>
                            <li><a href="#" onClick={(e) => handleScrollDiv(e, "#sect15")}>How to contact</a></li >
                        </ul >
                        <div className="mt-5" id="sect1">
                            <h3><b>1. Acceptance of the Privacy Policy</b></h3>
                            <p>
                                By accessing, connecting, clicking on, using, or engaging in our Services,
                                you indicate and confirm that you have consented and agreed to the terms of
                                this Privacy Policy. We will not use your Personal Information for any other
                                purpose that has not covered in this Privacy Policy or the T&amp;Cs without
                                providing you prior notification or obtaining your consent.
                            </p>
                            <p>
                                If you do not agree, either partially or wholly, to this Privacy Policy, you
                                must immediately discontinue accessing, connecting, using, or engaging in
                                our Services and METAVPAD Platform. This Privacy Policy does not apply to
                                services offered by other companies or service providers or other websites
                                linked from our Platform.
                            </p>
                        </div>
                        <div className="mt-5" id="sect2">
                            <h3><b>2. Our Relationship with you</b></h3>
                            <p>
                                METAVPAD has the relationship with individuals on its Services as described
                                below:
                            </p>
                            <p>
                                “<strong>User</strong>” is an individual providing personal information to
                                us via our Website or METAVPAD Platform, Medium pages, social media
                                accounts, or other means. For example, User can engage and complete
                                additional METAVPAD social media engagement tasks, to entitle for add up to
                                the whitelist for each IDO fundraising event or interact with us on our
                                social media accounts.
                            </p>
                            <p>
                                Hereinafter, we may refer to User as “<strong>you</strong>” in this Privacy
                                Policy.
                            </p>
                        </div>
                        <div className="mt-5" id="sect3">
                            <h3><b>3. Personal Information that we collect</b></h3>
                            <p>
                                For the purposes of this Privacy Policy, Personal Information is information
                                that can be used to identify, describe, relate, or associate with a
                                particular individual. The following are the types of information we
                                collect, store, and process when you use our services:
                            </p>

                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>3.1 Information provided by you when connecting with METAVPAD Platform and for KYC purpose.</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    Prior to connecting or participating in METAVPAD Platform to use our
                                    Services, you must provide us with your full name, birthday date,
                                    nationality, home address, location, government identification number
                                    (Identification Card/Passport Number and Date of the Issuance of
                                    Identification Card/Passport), telegram username, digital wallet address,
                                    email address, IP address, documentation for proof of address and other
                                    information which as we required which can be used to distinguish your
                                    identity, to access the METAVPAD Platform and for the Know Your Customer (“
                                    <strong>KYC</strong>”) Verification purpose. Additionally, you will use
                                    single-sign-on (also known as “<strong>OAuth</strong>”) by logging in to
                                    our Services using MetaMask. These services will authenticate your identity
                                    and provide you the option to share certain information with us such as your
                                    username and email address.
                                </p>
                                <p style={{ paddingLeft: 30 }}>
                                    Furthermore, you must be required to upload facial image data, namely photo
                                    images of your face and, provide additional information when firstly
                                    connecting with METAVPAD Platform such as linking to your Facebook account,
                                    Telegram, LinkedIn, GitHub, Google, or Twitter of other social media
                                    accounts, and more.
                                </p>
                            </div>

                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>3.2 Information collected by us as you use our services</b></h4>
                                <div className="mt-3">
                                    <h5 style={{ paddingLeft: 60 }}><b>3.2.1 Usage Information</b></h5>
                                    <p style={{ paddingLeft: 60 }}>
                                        When you engage or use our Services, we will monitor your information in
                                        relation to your usage of METAVPAD's Services through your device such as
                                        your IP address, phone number, the type of device you use to access our
                                        Platform, device information, which websites you visited before accessing
                                        our Services or Platform, browser type, Information about your browser, Date
                                        and Time you visit our Platform, Volume of data transmitted and network
                                        operator. This information will either be directly obtained by METAVPAD
                                        Platform or our website or through third party service providers.
                                    </p>
                                    <p style={{ paddingLeft: 60 }}>
                                        We collect this type of information to ensure that our interface and/or
                                        Platform is seamlessly accessible for all users around the world, except
                                        users in the prohibited jurisdictions.
                                    </p>
                                </div>

                                <div className="mt-3">
                                    <h5 style={{ paddingLeft: 60 }}><b>3.2.2 Communication Information</b></h5>
                                    <p style={{ paddingLeft: 60 }}>
                                        You hereby agree that we are entitled to collect and use or process the
                                        Personal Information you have provided on our Platform or generated through
                                        your usage of METAVPAD Services for the purpose of being able to communicate
                                        with you through messages, requests, emails, live chats, file attachments in
                                        connection with your transactions on METAVPAD Platform, or any other
                                        information related to your contact with METAVPAD's Services. If you contact
                                        us, we will keep a record of the information shared during the
                                        correspondence.
                                    </p>
                                </div>

                                <div className="mt-3">
                                    <h5 style={{ paddingLeft: 60 }}><b>3.2.3 Financial Information</b></h5>
                                    <p style={{ paddingLeft: 60 }}>
                                        You hereby agree that, for the purposes stipulated in this Privacy Policy,
                                        we are entitled to collect and use the information contained in or related
                                        to your financial information when you use our services. This includes,
                                        without limitation, your transaction history such as your initial
                                        cryptocurrency or token amount, your VLXPAD Tokens amount, your staking
                                        instructions, your wallet address(es) etc.
                                    </p>
                                    <p style={{ paddingLeft: 60 }}>
                                        We collect this financial information to monitor suspicious financial
                                        activities to protect you from fraud, resolution of legal cases, as well as
                                        any other purposes disclosed in this Privacy Policy.
                                    </p>
                                    <p style={{ paddingLeft: 60 }}>
                                        Parts of METAVPAD Services are public, including any information disclosed
                                        on our social media accounts, thus you acknowledge that such parts of
                                        METAVPAD Services may appear on search engines or other publicly available
                                        platforms, and may be “crawled”, searched and used by the third parties or
                                        other Users and Customers of the Services. Please do not post any
                                        information that you do not wish to reveal publicly.&nbsp;
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5" id="sect4">
                            <h3><b>4. How we collect Personal Information</b></h3>
                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>4.1 Information automatically collected by us</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    We will collect and process Personal Information automatically provided by
                                    you under the following situations.
                                </p>
                                <h5 className="mt-3" style={{ paddingLeft: 60 }}><b>4.1.1 when you visit and/or firstly connect with
                                    the Website;</b></h5>
                                <h5 className="mt-3" style={{ paddingLeft: 60 }}><b>4.1.2 when you voluntarily complete any user
                                    survey or provide feedback to us via emails or any other electronic channels
                                    including on our social media accounts;</b></h5>
                                <h5 className="mt-3" style={{ paddingLeft: 60 }}><b>4.1.3 when you use browser cookies or
                                    any other relevant software upon visiting the Website; and</b></h5>
                                <h5 className="mt-3" style={{ paddingLeft: 60 }}><b>4.1.4 other situations where we may automatically
                                    collect your information as described in</b></h5>
                                <p style={{ paddingLeft: 60 }}>this Privacy Policy, our T&amp;Cs, or other relevant agreements with you.</p>
                            </div>

                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>4.2 Information collected by the third-party</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    We will collect your Personal Information from the third parties as required
                                    or permitted to the extent of applicable laws. Information from the
                                    third-party sources will include, but will not limited to, public databases,
                                    ID verification partners, KYC Verification partners, blockchain data,
                                    marketing partners and resellers, advertising partners, and analytics
                                    providers.
                                </p>
                            </div>

                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>4.3 Anonymized and Aggregated Data</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    We will also collect other Information in the form of anonymized and
                                    aggregate information where all information will be combined and then
                                    removed of personally identifiable information, making it unusable for the
                                    identification of specific individuals. Aggregated data could be derived
                                    from your personal data, but it is not considered as personal data under
                                    applicable laws. For example, we may aggregate your usage data to calculate
                                    the percentage of users accessing a specific feature of METAVPAD Services.
                                    However, if we combine or connect aggregated data with your personal data so
                                    that it can directly or indirectly identify you, you acknowledge that we
                                    treat the combined data as personal data which will be used in accordance
                                    with this Privacy Policy.
                                </p>
                                <p style={{ paddingLeft: 30 }}>
                                    We use anonymized or aggregate users' information for the purposes
                                    stipulated in this Privacy Policy at any time, including gaining better
                                    understanding of users' needs and behaviours, conducting business
                                    intelligence and marketing initiatives, and detecting security threats. In
                                    this regard, we reserve the right to implement innovative technologies as
                                    they become available to be used for strategic planning and operational
                                    processes.
                                </p>
                            </div>
                        </div>

                        <div className="mt-5" id="sect5">
                            <h3><b>5. How we use Personal Information</b></h3>
                            <p>
                                Your Personal Information will be made available to need-to-know basis to
                                METAVPAD, our Affiliate, our Partners and/or within our group of companies.
                                This will include, but not be limited to, the employees and/or any persons
                                or third parties working for METAVPAD who are responsible for using and
                                protecting your Personal Information.
                            </p>
                            <p>
                                We use and process your Personal Information for the following purposes or
                                in the following ways:
                            </p>
                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>5.1 To provide our Services</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    We respect data protection principles, and process personal data only for
                                    specified, explicit, and legitimate purpose for which such personal data
                                    were provided. We primarily use your Personal information, either collected
                                    or delivered, in order to enable your use of METAVPAD's Services (including,
                                    but not limited to, processing transactions), to improve the efficiency of
                                    the Services, the process to participate in the IDO fundraising project
                                    event, and to verify your identity.
                                </p>
                            </div>

                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>5.2 To protect users</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    We use the information collected to protect our Platforms, Users'
                                    information, and archives. We also use IP addresses and cookies to detect
                                    suspicious activities and to protect against automated abuse such as spam,
                                    phishing, and Distributed Denial of Service (“DDoS”) attacks, and other
                                    security risks.
                                </p>
                            </div>

                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>5.3 To comply with regulations and compliance</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    For our legitimate interests, we will use the information in compliance with
                                    our legal obligations, global and local industry standards, and our
                                    AML/KYC/CTF procedures. In the case where it is strictly necessary (i.e., to
                                    protect the vital interests of the users or other natural persons, to
                                    prevent or mitigate fraud, to fulfil the purpose of public interest, or to
                                    pursue our reasonable interests), we may use and process your Personal
                                    Information without obtaining your consent. You hereby expressly authorise
                                    us to disclose any and all information relating to you in our possession to
                                    any law enforcement or government officials upon the only upon a valid
                                    request.
                                </p>
                            </div>

                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>5.4 For measurement, research and development purposes-</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    We actively measure and analyse your information to understand the way you
                                    use and interact with our Services, and our Platform, and to provide you
                                    with the unique and personalized experience. For example, we allow you to
                                    use social media plugins on our Services (e.g., Telegram, Twitter, etc.), we
                                    keep track of your preferences such as display name, time zone, and so on.
                                    This review is continuously conducted by our operation teams to continuously
                                    improve our Platforms' performance and to resolve issues with user
                                    experience.
                                </p>
                                <p style={{ paddingLeft: 30 }}>
                                    In addition, we use such information to monitor trends, and improve our
                                    Services, our administration, the content and layout of the Website, and to
                                    develop new Services for you.
                                </p>
                            </div>

                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>5.5 For communication purposes</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    We use your Personal Information, collected or derived from your
                                    communication devices or your email address, to interact with you directly,
                                    to provide you with necessary support, and/or to keep you informed of
                                    wallets connected, transactions, staking instructions, security details, as
                                    well as other aspects. All direct communications will be appropriately
                                    maintained by us, or the service providers designated by us, to be reviewed
                                    for accuracy, retained as evidence, or used to perform other statutory
                                    requirements or other obligations as stipulated in this Privacy Policy and
                                    the T&amp;Cs.
                                </p>
                            </div>

                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>5.6 To enforce our T&amp;Cs, and other relevant agreements</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    Personal Information is also used to enforce our T&amp;Cs continuously and
                                    actively among, and other agreements relating to the Services on METAVPAD
                                    Platform. Activities in this regard include, but are not limited to,
                                    reviewing, processing, verifying, investigating, mitigating and preventing
                                    any potentially prohibited or illegal activities that may violate preceding
                                    provisions, or disclose relevant information to third parties in accordance
                                    therewith. In light of this, we shall be entitled to freeze/close/delete
                                    accounts or any user activity on the Website as necessary to any User found
                                    to be engaged in any activities that violate our T&amp;Cs, and other
                                    agreements.
                                </p>
                            </div>

                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>5.7 For marketing and advertising</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    We will share your Personal Information with our marketing partners for the
                                    purposes of targeting, modelling, and/or identifying analytics as well as
                                    marketing and advertising. We may send you marketing communications to alert
                                    you about new Services, yet you can opt-out of our marketing activities at
                                    any time.
                                </p>
                            </div>

                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>5.8 For other purposes</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    Provided that we need to process or use your Personal Information for other
                                    purposes, not stipulated in this Privacy Policy, we will notify or request
                                    your consent to use such information. We will not use your Personal
                                    Information for purposes other than the purposes stipulated in this Privacy
                                    Policy without your prior consent except where it is necessary for our
                                    legitimate interests (or for our Affiliate, our Partners and/or our group of
                                    companies) and your interests and fundamental rights do not override those
                                    interests.
                                </p>
                            </div>
                        </div>

                        <div className="mt-5" id="sect6">
                            <h3><b>6. How we protect Personal Information</b></h3>
                            <p>
                                METAVPAD takes reasonable care to protect the METAVPAD's security on
                                METAVPAD Platform and website as well as your Personal Information from
                                loss, misuse, disclosure, alteration, and destruction. We take reasonable
                                steps to maintain physical, technical, and employ procedural safeguard to
                                ensure the confidentiality, integrity of your Personal Information. The
                                safeguards include the use of firewalls, and data encryption, enforcing
                                physical access controls to our premise and files, and limiting access to
                                Personal Information only to those employees, agents or third parties who
                                need access to that information to process it for us.
                            </p>
                            <p>
                                We also implement certain reasonable security measures to protect your
                                Personal Information from unauthorized access, and such security measures
                                are in compliance with the security practices and procedures as prescribed
                                under the applicable laws.
                            </p>
                            <p>
                                However, you agree and acknowledge that it is impossible to fully guarantee
                                the security of your Personal Information by implementing the
                                above-mentioned safeguard and measures. It is not absolute protection to
                                your Personal Information and by accessing the Services.
                            </p>
                            <p>
                                You agree that we will not hold any responsibility for the acts committed by
                                those who gain unauthorized access or abuse your information and services.
                                In addition, we will not hold any responsibility for any act committed by
                                the third-party service providers who are contractually engaged with us to
                                maintain an appropriate security measure for protecting your Personal
                                Information. Thus, you are recommended to understand this responsibility and
                                to independently take safety precautions to protect your Personal
                                Information, particularly your credential information such your wallet
                                address. You hereby agree that we will not be liable for any information
                                leakage and other damage or loss not caused by our intention or gross
                                negligence, including, but not limited to, hacker attacks, power
                                interruptions, or unavoidable technical failures.
                            </p>
                        </div>
                        <div className="mt-5" id="sect7">
                            <h3><b>7. How long we retain Personal Information</b></h3>
                            <p>
                                You are aware that your Personal Information will continue to be stored and
                                retained by us or by a third-party processor engaged by us for KYC
                                Verification while you have been using or accessing METAVPAD Platform and
                                the Services, and after your use of the Services for a reasonable period of
                                time stipulated under the applicable law.
                            </p>
                            <p>
                                In addition, we may be required by applicable laws and regulatory
                                requirements to retain certain information, including your Personal
                                Information, your identification verification materials, information
                                relevant to AML/KYC/CTF procedures, information related to staking
                                instructions, account agreements, and other agreements between us and
                                third-parties, account statements, and other records after you stop
                                accessing or participating in METAVPAD Platform or its website.
                            </p>
                        </div>

                        <div className="mt-5" id="sect8">
                            <h3><b>8. Cookies</b></h3>
                            <p>
                                We will use cookies and other technologies or methods of web and analytic
                                tools to gather, store, and analyse certain information related with your
                                access to and activities through the Services, including when you visit the
                                Website.
                            </p>
                            <p>
                                A "cookie" is a small piece of information that a website assigns to your
                                device while you are viewing a website. Cookies are beneficial and may be
                                used for various purposes. These purposes include, among other things,
                                allowing you to navigate between pages efficiently, enabling automatic
                                activation of certain features, remembering your preferences and making the
                                interaction between you and the Services quicker, easier and smoother. Our
                                Website will use the following types of cookies:
                            </p>
                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>8.1 Strictly Necessary Cookies</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    These cookies are essential to enable you to log in, navigate a website, and
                                    use its features or to provide a service requested by you. We will not need
                                    to obtain your consent in order to use these cookies.
                                </p>
                            </div>
                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>8.2 Functionality Cookies</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    These cookies allow the website to remember choices you make (such as your
                                    username, language, or the region you reside in) and provide enhanced, more
                                    personal features. The information these cookies collect remains anonymous,
                                    and they cannot track your browsing activity on other websites.
                                </p>
                            </div>
                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>8.3 Performance cookies</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    These cookies collect information about how you use a website, for example,
                                    which pages you go to most often, how much time you spend on a page, record
                                    difficulties you may experience while using the website such as error
                                    messages. All information collected by these cookies is aggregated and
                                    therefore anonymous. It is only used to improve the efficiency of the
                                    website.
                                </p>
                            </div>
                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>8.4 Targeting Cookies or Advertising Cookies</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    These cookies are used to deliver advertisements tailored to you and your
                                    interests specifically. They are also used to limit the number of times you
                                    see an advertisement as well as help measure the effectiveness of the
                                    advertising campaign. These cookies remember that you have visited a
                                    website, and this information is shared with other organizations such as
                                    advertisers. Quite often targeting or advertising cookies will be linked to
                                    the sites' functionality provided by the other organizations.
                                </p>
                                <p style={{ paddingLeft: 30 }}>
                                    You may remove these cookies by following the instructions of your device
                                    preferences. However, if you choose to disable cookies, some features of
                                    METAVPAD Services may not operate properly or become inaccessible, and your
                                    online experience may be limited. For Further information please visit our
                                    Cookies Policy.
                                </p>
                            </div>
                        </div>

                        <div className="mt-5" id="sect9">
                            <h3><b>9. Sharing your Personal Information</b></h3>
                            <p>
                                We will not rent, sell, or disclose your Personal Information to any third
                                parties, except to those who require access to the information to perform
                                their tasks and duties under the binding agreements executed with us and to
                                share with third parties who have a legitimate purpose for accessing it.
                            </p>
                            <p>
                                We may share, transfer, disclose, or allow access to your Personal
                                Information to the following third parties for the purposes described below:
                            </p>
                            <ul>
                                <li>
                                    We may disclose Personal Information to the third parties in order to
                                    administer or process a transaction, or services you have authorized or
                                    requested, or in the context of facilitating the execution of a
                                    transaction, or we may disclose Personal Information to third parties that
                                    provide supporting services, or analytical information for the purpose of
                                    the Service improvement, only where we have a lawful basis to do so or
                                    under an agreement or legal requirements for the transfer of Personal
                                    Information.
                                </li>
                                <li>
                                    We will be required to verify your identities by applicable laws and
                                    regulatory requirements and rely on third-party services to perform these
                                    verifications. Personal Information that you provide to us during the
                                    initial connecting process is passed to these services at KYC Verification
                                    process and on an ongoing basis thereafter.
                                </li>
                                <li>
                                    We may disclose or transfer a part of your Personal Information we collect
                                    if we are involved in a business transition or any merger and/or
                                    acquisition (M&amp;A) projects. In such M&amp;A event, your Personal
                                    Information might be among the assets transferred, but it will be
                                    protected and secure in accordance with this Privacy Policy.
                                </li>
                                <li>
                                    We may share your Personal Information with law enforcement, and
                                    government officials when we are compelled to do so by a subpoena, a valid
                                    court order to prevent financial loss, to report suspected illegal
                                    activity or to investigate violations of any of our T&amp;Cs or any other
                                    applicable
                                </li>
                                <li>
                                    We may share your Personal Information with third-party processors acting
                                    on behalf of us or engaged by us to process your Personal Information for
                                    us, our Affiliate and/or our Partners.
                                </li>
                            </ul>
                            <p>
                                All Affiliate and third parties with whom we share your Personal Information
                                have their privacy policies. However, they will be expected to protect this
                                information in a manner that aligns with the provisions described in this
                                Privacy Policy.
                            </p>
                        </div>

                        <div className="mt-5" id="sect10">
                            <h3><b>10. Third-Party Collection of Personal Information</b></h3>
                            <p>
                                This Privacy Policy only addresses the use and disclosure of the Personal
                                Information we collect from you. To the extent that you disclose your
                                information to other parties through the use of our Services such as by
                                clicking on a link to any other websites of our partner networks, different
                                rules regarding Privacy Policies may apply to their use or disclosure of the
                                Personal Information you disclose to them.
                            </p>
                            <p>
                                You hereby acknowledge that we will not be responsible for the products,
                                services, or descriptions of products or services that you receive from the
                                third-party websites or to the content or privacy practices of the
                                third-party websites. Also, this Privacy Policy will not be applied to any
                                such third-party products and services that you access through METAVPAD
                                Platform. You are knowingly and voluntarily assuming all risks of using
                                third-party websites to purchase products or services, and you agree that we
                                will have no liability whatsoever concerning such third-party websites and
                                your usage of them.
                            </p>
                            <p>
                                Your relationship with these third parties and their services and tools is
                                independent of your relationship with us. These third parties may allow you
                                to permit/restrict the information that is collected. It may be in your
                                interest to individually restrict or enable such data collections.
                            </p>
                        </div>

                        <div className="mt-5" id="sect11">
                            <h3><b>11. Designated Countries Privacy Right and Your Legal Rights</b></h3>
                            <p>
                                This provision applies to Users of the Services that are located in the
                                European Economic Area, United Kingdom and/or Switzerland (herein
                                collectively referred as “<strong>Designated Countries</strong>”) at the
                                time of Data or Personal Information being collected. We may ask your
                                information regarding your nationality, your location, which country you are
                                in when you use our Services or Platform, or we may rely on your IP address.
                                We cannot apply this provision to Users who do not provide information about
                                the location or obfuscates location information so as not to appear located
                                in the Designated Countries.
                            </p>
                            <p>
                                We use and process your Personal Information based on a valid lawful basis
                                of the GDPR and any equivalent regulations (collectively referred as “
                                <strong>Data Protection Laws</strong>”). The lawful basis for processing
                                your Personal Information includes (i) your consent, (ii) performance of a
                                contract, (iii) a legitimate interest, (iv) a compliance with legal
                                obligations, (v) protection your vital interest, and (vi) public task.
                            </p>
                            <p>
                                In the event of any conflict or inconsistency between any term in this
                                provision and other terms contained in this Privacy Policy, the term in this
                                provision will govern and control over Users in the Designated Countries.
                            </p>
                            <p>
                                Under certain circumstances when you use our Services, you have the rights
                                under the Data Protection Laws in relation to your Personal Information.
                                These rights include the followings:
                            </p>

                            <div className="mt-4" id="sect11_1">
                                <h4 style={{ paddingLeft: 30 }}><b>11.1 Right to be informed</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    You reserve the right to be informed about the collection, use, and process
                                    of your Personal Information.
                                </p>
                            </div>

                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>11.2 Right of access</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    You reserve the right to make a request for a copy of the Personal
                                    Information we hold about you and specific information regarding our
                                    processing of this information.
                                </p>
                            </div>

                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>11.3 Right to rectify</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    You reserve the right to request us to update, correct, or complete your
                                    Personal Information that you believe to be outdated, inaccurate, or
                                    incomplete. You will rectify at any time by informing us via our contact
                                    channels.
                                </p>
                            </div>

                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>11.4 Right to erasure (“Right to be Forgotten”)</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    You may request to have your Personal Information deleted from our record
                                    where (i) your Personal Information is no longer necessary to be used for
                                    the Services or on the Platform, (ii) Personal Information was collected in
                                    relation to processing that you previously consented to, but later withdrew
                                    such consent, or (iii) your Personal Information was collected in relation
                                    to processing activities to which you object, and there are no overriding
                                    legitimate grounds for our processing.
                                </p>
                                <p style={{ paddingLeft: 30 }}>
                                    Please be informed that the Right to be Forgotten mentioned above is not an
                                    absolute right. We are compelled to fulfil your request only where the
                                    retention of your Personal Information might cause an infraction of the GDPR
                                    or applicable law to which we are subject. We shall exercise reasonable
                                    efforts in having your Personal Information erased to the extent required by
                                    the GDPR or applicable law including in communicating erasure to our
                                    recipients of your Personal Information unless that proves to be impossible
                                    or a disproportionate effort.
                                </p>
                            </div>

                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>11.5 Right to data portability</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    You reserve the right to request to transfer a machine-readable copy of your
                                    Personal Information to you or the third-party of your choice. We will
                                    provide you, or third-party, your Personal Information in a machine-readable
                                    format. This right only applies to Personal Information you have consented
                                    us to use.
                                </p>
                            </div>

                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>11.6 Right to restrict processing</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    You may request us to restrict or suppress the processing of your Personal
                                    Information under certain circumstances as follows:
                                </p>
                                <ul style={{ paddingLeft: 60 }}>
                                    <li>to establish the accuracy of the Personal Information;</li>
                                    <li>
                                        when the processing is considered unlawful, but you do not wish to have
                                        your Personal Information erased;
                                    </li>
                                    <li>
                                        where we no longer need to process your Personal Information, but the
                                        information must be retained for the establishment, exercise or defence of
                                        legal claims; and
                                    </li>
                                    <li>
                                        where you have objected to our processing your Personal Information, but
                                        we need to determine whether our legitimate interest overrides your
                                    </li>
                                </ul>
                            </div>

                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>11.7 Right to object</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    You may object to our reliance on our legitimate interests as the basis of
                                    our processing of your Personal Information that impacts your rights. You
                                    may also object to our process of your Personal Information for direct
                                    marketing purposes.
                                </p>
                            </div>

                            <div className="mt-4">
                                <h4 style={{ paddingLeft: 30 }}><b>11.8 Right to withdraw consent ("Opt-out")</b></h4>
                                <p style={{ paddingLeft: 30 }}>
                                    You reserve the right to withdraw your consent at any time where we are
                                    relying on it to process your Personal Information. Withdrawing your consent
                                    does not affect the lawfulness of our processing of your Personal
                                    Information prior to withdrawing.
                                </p>
                                <p style={{ paddingLeft: 30 }}>
                                    If you wish to exercise these rights as aforementioned, please inform and
                                    contact us via our Platform, including the Website or send a request to{" "}
                                    <a href="mailto:support@pulsepad.io">support@metavpad.com</a>
                                </p>
                                <p style={{ paddingLeft: 30 }}>
                                    Please also be noted that there are some limitations when you wish to
                                    exercise any one of these individual rights. We may limit your individual
                                    rights in the following situations:
                                </p>
                                <ul style={{ paddingLeft: 60 }}>
                                    <li>
                                        Where denial of access is required by laws;
                                    </li>
                                    <li>
                                        When granting access would have a negative impact on others' privacy;
                                    </li>
                                    <li>
                                        In order to protect our rights and properties;
                                    </li>
                                    <li>
                                        Where the request burdensome.
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="mt-5" id="sect12">
                            <h3><b>12. Cross Border Transfer of Personal Information</b></h3>
                            <p>
                                As a part of your use of and to ensure better and seamless delivery of the
                                Services to you, we may store, process, analyse, transfer your Personal
                                Information in location globally, including countries other than your home
                                jurisdiction, and/or locations outside the Designated Countries where there
                                may have data protections with less protection than the EU GDPR or the
                                equivalent data protection laws.
                            </p>
                            <p>
                                Our Service and Platform contain the transmission of your Personal
                                Information to or to be transferred to or to be processed outside of the
                                country where you are located or outside the Designated Countries, it is not
                                suggestible if your Personal Information is restrictive.
                            </p>
                            <p>
                                However, if you wish to continue using our Services outside the Designated
                                Countries and your home locations, we will ensure that we will transfer your
                                Personal Information only to country that has the required contractual
                                provisions for transferring Personal Information in place with the third
                                parties to which your Information is transferred, or to country where is
                                approved by the EU authorities as providing an adequate level of data
                                protection or enter into legal agreements ensuring an adequate level of data
                                protection in the jurisdiction of the party receiving the information. In
                                this manner, you hereby expressly consent to this transfer, and agree that
                                we will not be responsible for any additional T&amp;Cs, policies or any
                                other guidelines implemented by any party receiving the information.
                            </p>
                        </div>

                        <div className="mt-5" id="sect13">
                            <h3><b>13. Limitation</b></h3>
                            <p className="mt-4" style={{ paddingLeft: 30 }}>
                                <b>13.1 Our Services are not designed for the individuals who
                                    are under the age of 18 or under the legal age to provide consent under the
                                    applicable laws of country or jurisdiction where you are using our Services
                                    or Platform. If you are not over 18 or of the legal age of your country, you
                                    will not provide any Personal Information to us, or to engage in our
                                    services. We reserve the right to access and verify any Personal Information
                                    collected from you. If we are aware that you are under 18 or under the legal
                                    age who already shared your Personal Information with us, we will then
                                    discard such information and block your access to METAVPAD Platform or our
                                    website immediately.</b>
                            </p>
                            <p className="mt-4" style={{ paddingLeft: 30 }}>
                                <b>13.2 We cannot guarantee that any losses, misuses,
                                    unauthorized accession, or alteration of your Personal Information will not
                                    occur. You hereby agree that you play a vital role in protecting your
                                    Personal Information, including your credentials.</b>
                            </p>
                        </div>

                        <div className="mt-5" id="sect14">
                            <h3><b>14. Updates or amendments to the Privacy Policy</b></h3>
                            <p>
                                We will revise and update this Privacy Policy periodically, at our sole
                                discretion, and the most current version will be published on the Website or
                                METAVPAD Platform (as reflected in the "Last Revised" heading).
                            </p>
                            <p>
                                In the event of any material change which may affect your usage on our
                                Services and Platform, we will notify you by means of a prominent notice in
                                advance of such change coming into effect. A prominent notice may include
                                sending through your email address or other communication channels you
                                provide to us or providing you with an in-application notice such as a
                                banner or pop-up alerting you of the change on the website. We strongly
                                encourage you to review this Privacy Policy actively. If you do not agree,
                                either partially or wholly, the revised Privacy Policy, you should
                                discontinue accessing or using our Services. Your continued access to and
                                use of our Services after any changes to this Privacy Policy constitutes
                                your consent to any changes and agree to continue using the Services.
                            </p>
                            <p>
                                Furthermore, we may provide you with the “just-in-time” disclosures or
                                additional information about our data collection process, purposes for
                                processing, or other information with respect to collection of your personal
                                information. The purpose of the just-in-time notification is to supplement
                                or clarify our privacy practice or provide you with additional choices about
                                how we process your personal information.
                            </p>
                        </div>

                        <div className="mt-5" id="sect15">
                            <h3><b>15. How to contact METAVPAD</b></h3>
                            <p>
                                For any questions regarding this Privacy Policy, your Personal Information
                                collected or proceeded by us, or in case you would like to exercise one of
                                your legal privacy rights as stipulated in Clause 11, please submit your
                                requests to <a href="mailto:support@pulsepad.io">support@metavpad.com</a>{" "}
                                and visit our website at &nbsp;
                                <a href="https://pulsepad.io/">https://metavpad.com/</a>.
                            </p>
                        </div>
                        <a href="#nav" className="top" title="Scroll to navigation" onClick={(e) => handleScrollTop(e)}>»</a>
                        
                        <p>&nbsp;</p>
                        <p id="_ftn0">
                            <a className="text-white" href="#" name="_ftn1" onClick={(e) => handleScrollDiv(e, "#_ftn1")}>
                                [1]
                            </a>&nbsp;Version 3.0
                        </p>
                    </div >
                </div >
            </Container >
        </div >
    </>
}
export default PrivacyPolicyPage;