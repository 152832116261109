export const MODE = process.env.REACT_APP_MODE || "TESTNET";

export const BSC_EXPLORER = {
    56: "https://bscscan.com",
    // 111: "https://evmexplorer.testnet.velas.com",
   
};



export const STAKING_CONTRACT_ADDRESS = {
    56: "0x296c0065e281cFFc05be4E743c1195B621256d0A",
};



export const POOL_INTERVAL = 15000;




export const BACKEND_URI = 'https://app.metavpad.com';
// export const BACKEND_URI = 'https://t.nftlaunch.network'

//https://staking.metavpad.com/

export const NODE_URI = {
    56: [
        "https://bsc-dataseed.binance.org/",
        "https://bsc-dataseed1.defibit.io/",
        "https://bsc-dataseed1.ninicoin.io/",
      ],
    // 111: ["https://testnet.velas.com/rpc"]
        // 1: ['https://mainnet.infura.io/v3/e6d8a9c75b2e4e8c9d1e4c57446ffded']
};

export const KYC_BACK_END_URI = "https://bscpad.com";

export const chainIdsSupport = [56];

export const defaultChainId = 56
    // process.env.REACT_APP_MODE === "MAINNET" ? 56 : 97;

export const showKYC = 1000.0