import React, { useEffect, useLayoutEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { actGetListProjects } from "../../redux/action/user";
import ClosedProjectsComponent from "./components/ClosedProjectsComponent";
import OpeningProjectsComponent from "./components/OpeningProjectsComponent";
import WaitingProjectsComponent from "./components/WaitingProjectsComponent";
import './index.css';
import { useLatestBlockNumber } from "../../hook/useState";
import $ from 'jquery';
import { ROUTES } from "../../constants";
import { Link } from "react-router-dom";

const ProjectsPage = () => {
    const dispatch = useDispatch();
    const latestBlock = useLatestBlockNumber();
    useLayoutEffect(() => {
        function setBackgroundImage() {
            if ($('.pd-projetcs-page-inner').height > 1000) {
                $('.pd-projetcs-page-inner').removeClass('no-bg');
            } else {
                $('.pd-projetcs-page-inner').addClass('no-bg');
            }
        }
        window.addEventListener('resize', setBackgroundImage);
        setBackgroundImage();
        return () => window.removeEventListener('resize', setBackgroundImage);
    }, []);

    useEffect(() => {
        document.body.className = 'project-template';
        return (() => {
            document.body.className = '';
        })
    }, [])

    useEffect(() => {
        dispatch(actGetListProjects())
    }, [latestBlock])

    return <div className="projects-page">
        <div className="pd-banner">
            <Container>
                <div className="pd-slogan">
                    <h1>METAVPAD</h1>
                    <h2> BUILDING THE METAVERSE,<br />ONE BLOCK AT A TIME</h2>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-5">
                    <a href="https://metavpad.com/" target="_blank" className="btn-pd me-3">Homepage</a>
                    <Link to={ROUTES.STACKING} className="btn-pd">Staking</Link>
                </div>
            </Container>

        </div>
        <div className="text-center d-none d-lg-block" style={{ marginTop: '-60px', marginBottom: '-150px' }}>
            <img src="/images/sect10.jpg" className="img-fluid" />
        </div>
        <div className="pd-projetcs-page-inner no-bg">
            <OpeningProjectsComponent />
            <WaitingProjectsComponent />
        </div>
        <ClosedProjectsComponent />
    </div>
}
export default ProjectsPage;