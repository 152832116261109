import React, { useEffect, useState } from "react";
import { useDispatch, } from "react-redux";
import { Link } from "react-router-dom";
import { BSC_EXPLORER, STAKING_CONTRACT_ADDRESS } from "../../_configs";
import { BigNumber } from "bignumber.js";
import { helpers } from "../../shared/utils";
import StakingPortalPage from "../../shared/layout/StakingPortal";
import { useBnbBalance, useIsConnectWallet, usePadTokenBalance, useStakingInfo, useStakingWalletInfo, useWeb3Utils, useTokenPadAddress } from "../../shared/components/hook/useSate";
import { useActiveWeb3React } from "../../hook";
import { ACTION_CONST, ROUTES, ACTION_STATUS } from "../../constants";

const StakingPage = () => {
    const dispatch = useDispatch();
    const { account, chainId, library } = useActiveWeb3React();
    const isConnectWallet = useIsConnectWallet();
    const walletUtils = useWeb3Utils();
    const stakingInfo = useStakingInfo()
    const stakingWalletInfo = useStakingWalletInfo();
    const bnbBalance = useBnbBalance();
    const bscPadBalance = usePadTokenBalance();
    
    const [acceptTerm, setAcceptTerm] = useState(false);
    const [swapAmount, setSwapAmount] = useState('0')
    const [isMaxAmount, setIsMaxAmount] = useState(false);
    const [swapCurrentStep, setSwapCurrentStep] = useState(1);
    const [enableSwapBtn, setEnableSwapBtn] = useState(false);
    const [transactionHash, setTransactionHash] = useState("")
    const [isSubmitOK, setIsSubmitOK] = useState(false);

    const tokenPadAddress = useTokenPadAddress();

    useEffect(() => {
        if (swapCurrentStep === 1) {
            if (acceptTerm &&
                isConnectWallet &&
                !stakingInfo["isStakingPaused"] &&
                parseFloat(bscPadBalance) > 0 &&
                bnbBalance > 0 &&
                stakingWalletInfo["withdrawTimestamp"] === 0
            ) {
                setEnableSwapBtn(true);
            } else {
                setEnableSwapBtn(false);
            }
        }
    }, [acceptTerm, isConnectWallet, bnbBalance, bscPadBalance, stakingInfo, stakingWalletInfo, swapCurrentStep])

    const swapBack = () => {
        if (swapCurrentStep === 1) {
            return;
        } else {
            setSwapCurrentStep(swapCurrentStep - 1);
        }
    }

    const swapNext = async () => {
        if (swapCurrentStep === 5) return;
        if (swapCurrentStep === 1 && parseFloat(swapAmount) === 0) {
            setEnableSwapBtn(false);
            setSwapCurrentStep(swapCurrentStep + 1);
            return;
        }
        // console.log("tokenPadAddress==>", tokenPadAddress);
        if (swapCurrentStep === 3) {
            // click approve
            if (walletUtils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                });
                let amount = isMaxAmount ? bscPadBalance : swapAmount
                //check allowance 
                const allowance = await walletUtils.getAllowance(tokenPadAddress, STAKING_CONTRACT_ADDRESS[chainId]);

                if (BigNumber(allowance).gte(BigNumber(amount))) {
                    setSwapCurrentStep(4);
                    dispatch({
                        type: ACTION_CONST.REQUEST_DONE,
                    });
                    return;
                }

                walletUtils.approve({ tokenContractAddress: stakingInfo["tokenAddr"], contractAddress: STAKING_CONTRACT_ADDRESS[chainId], amount: amount }, (data) => {
                    //function success
                    if (data.status === ACTION_STATUS.APPROVED) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        });
                        dispatch({
                            type: ACTION_CONST.ALERT_SUCCESS,
                            message: "Approve Tokens successfully!"
                        });
                        setSwapCurrentStep(4);
                    }
                    //function fails
                    if (data.status === ACTION_STATUS.APPROVE_FAILS) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        dispatch({
                            type: ACTION_CONST.ALERT_FAILS,
                            message: "Failed to Approve Tokens!"
                        })
                    }
                })
            }
        } else if (swapCurrentStep === 4) {
            // click deposit
            if (walletUtils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                })
                walletUtils.stakingDeposit({ amount: isMaxAmount ? bscPadBalance : swapAmount }, (result) => {
                    if (result.status === ACTION_STATUS.STAKING_DEPOSIT_SUCCESS) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        setIsSubmitOK(true)
                        setTransactionHash(result.txID)

                        //change to result screen
                        setSwapCurrentStep(5);

                    }
                    if (result.status === ACTION_STATUS.STAKING_DEPOSIT_FAIL) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        dispatch({
                            type: ACTION_CONST.ALERT_FAILS,
                            message: "Deposit stake fail!"
                        })
                    }
                })
            }
        } else {
            setSwapCurrentStep(swapCurrentStep + 1);
        }
    }


    //handle input swap change
    const handleInputSwap = (e) => {
        setIsMaxAmount(false);

        if (helpers.isFloatFormatted(e.target.value, 4)) {
            setSwapAmount(e.target.value);

            //check input
            if (BigNumber(e.target.value).lte(BigNumber(bscPadBalance)) && BigNumber(e.target.value).gt(BigNumber(0))) {
                setEnableSwapBtn(true);
            } else {
                setEnableSwapBtn(false);
            }
        }
    }

    const submitDone = () => {
        setSwapAmount('0');
        setIsMaxAmount(true);
        setSwapCurrentStep(1);
        setIsSubmitOK(false);
    }

    const handleMaxButtonClick = () => {
        setSwapAmount(helpers.formatNumberDownRound(bscPadBalance, 4));
        setEnableSwapBtn(true);
        setIsMaxAmount(true);
    }

    return (
        <StakingPortalPage selectedTab={1}>
            <div className="tab-pane fade show active" id="stake" role="tabpanel" aria-labelledby="stake-tab">
                <div className="row justify-content-center mb-4">
                    <div className="col-lg-12">
                        <div className="bs-stepper w-100">
                            <div className="bs-stepper-header" role="tablist">
                                <div className={`step ${swapCurrentStep >= 1 ? 'active' : ''}`} id="swapButtonStep1">
                                    <button type="button" className="step-trigger ps-0">
                                        <img src={swapCurrentStep >= 1 ? '/images/check-active.png' : '/images/check.png'} height="25" />
                                        <span className="bs-stepper-label first">Checkpoints</span>
                                    </button>
                                </div>
                                <div className={`line ${swapCurrentStep >= 2 ? 'active' : ''}`} id="swapLineStep1" />
                                <div className={`step ${swapCurrentStep >= 2 ? 'active' : ''}`} id="swapButtonStep2">
                                    <button type="button" className="step-trigger">
                                        <img src={swapCurrentStep >= 2 ? '/images/check-active.png' : '/images/check.png'} height="25" />
                                        <span className="bs-stepper-label">Amount Stake</span>
                                    </button>
                                </div>
                                <div className={`line ${swapCurrentStep >= 3 ? 'active' : ''}`} id="swapLineStep2" />
                                <div className={`step ${swapCurrentStep >= 3 ? 'active' : ''}`} id="swapButtonStep3">
                                    <button type="button" className="step-trigger">
                                        <img src={swapCurrentStep >= 3 ? '/images/check-active.png' : '/images/check.png'} height="25" />
                                        <span className="bs-stepper-label">Pre-authorization</span>
                                    </button>
                                </div>
                                <div className={`line ${swapCurrentStep >= 4 ? 'active' : ''}`} id="swapLineStep3" />
                                <div className={`step ${swapCurrentStep >= 4 ? 'active' : ''}`} id="swapButtonStep4">
                                    <button type="button" className="step-trigger">
                                        <img src={swapCurrentStep >= 4 ? '/images/check-active.png' : '/images/check.png'} height="25" />
                                        <span className="bs-stepper-label">Confirm</span>
                                    </button>
                                </div>
                                <div className={`line ${swapCurrentStep >= 5 ? 'active' : ''}`} id="swapLineStep4" />
                                <div className={`step ${swapCurrentStep >= 5 ? 'active' : ''}`} id="swapButtonStep5">
                                    <button type="button" className="step-trigger">
                                        <img src={swapCurrentStep >= 5 ? '/images/check-active.png' : '/images/check.png'} height="25" />
                                        <span className="bs-stepper-label last">Confirmation</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="">
                            <div className="p-sidebar-card-body">
                                {swapCurrentStep === 1 &&
                                    <div className="bs-stepper-content" id="SwapStep1">
                                        <div className="text-center mb-5">
                                            <h4 className="mb-2 pb-1 pd-step-title">Checkpoints</h4>
                                            <p className="step-desc">The following conditions must be met to proceed:</p>
                                        </div>

                                        <div className="grant__bordered bordered mb-0">
                                            <div className="row pd-card-steps">
                                                <div className="col-xl-3 col-md-6">
                                                    <div className={`pd-card-step ${isConnectWallet ? 'selected' : ''}`} >
                                                        <div className="pd-card-step-title">Connected MetaMask</div>
                                                        <div className="pd-card-step-body mt-2">
                                                            If not connected, click the "Connect Wallet" button in the top right corner
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3 col-md-6">
                                                    <div className={`pd-card-step ${parseFloat(bscPadBalance) > 0 ? 'selected' : ''}`} >
                                                        <div className="pd-card-step-title">$METAV available</div>
                                                        <div className="pd-card-step-body mt-2">
                                                            Current Balance: {helpers.formatNumberDownRoundWithExtractMax(bscPadBalance, 4)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3 col-md-6">
                                                    <div className={`pd-card-step ${bnbBalance > 0 ? 'selected' : ''}`} >
                                                        <div className="pd-card-step-title">BNB available</div>
                                                        <div className="pd-card-step-body mt-2">
                                                            BNB is required to pay transaction fees on the Binance Smart Chain network.<br />
                                                            BNB Balance: {helpers.formatNumberDownRoundWithExtractMax(bnbBalance, 4)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3 col-md-6">
                                                    <div className={`pd-card-step ${stakingWalletInfo["withdrawTimestamp"] === 0 ? 'selected' : ''}`} >
                                                        <div className="pd-card-step-title">Eligible to stake</div>
                                                        <div className="pd-card-step-body mt-2">
                                                            You cannot stake if you have an active $METAV unstake/withdrawal request
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-4 mb-3 text-center">
                                            <div className="form-check">
                                                <input className="form-check-input float-none me-1"
                                                    type="checkbox" defaultValue id="flexCheckDefault"
                                                    onChange={() => setAcceptTerm(!acceptTerm)} />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    &nbsp;I have read the&nbsp;
                                                    <Link target="_blank" to={ROUTES.TERM_OF_USES}>Terms and Conditions </Link>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {swapCurrentStep === 2 &&
                                    <div className="bs-stepper-content" id="SwapStep2">
                                        <div className="text-center">
                                            <h4 className="mb-2 pb-1 pd-step-title">Amount Stake</h4>
                                            <p className="step-desc text-white">Please enter the amount of $METAV you want to stake</p>
                                            <p className="step-desc">Any fees applied at Unstake &amp; Withdraw stage will be based on the date you last staked.</p>
                                            <div className="mx-auto" style={{ maxWidth: '100%', width: '400px' }}>
                                                <div className="p-form-group">
                                                    <label className="form-label p-main-text">Amount</label>
                                                    <div className="input-group pd-input-amount">
                                                        <input type="number" className="form-control" placeholder={0.0} value={swapAmount} onChange={(e) => handleInputSwap(e)} />
                                                        <button type="button" className="btn-pd" onClick={handleMaxButtonClick}>MAX</button>
                                                    </div>
                                                </div>
                                                <div className="font-14 mt-2">
                                                    Balance: <b className="text-pd">{helpers.formatNumberDownRound(bscPadBalance, 4)}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {swapCurrentStep === 3 &&
                                    <div className="bs-stepper-content" id="SwapStep3">
                                        <div className="text-center">
                                            <h4 className="mb-2 pb-1 pd-step-title">Pre-authorization</h4>
                                            <p className="step-desc">Required transaction 1 of 2</p>
                                            <p className="step-desc">In this step, you grant access to the staking smart contract to accept your $METAV</p>
                                        </div>
                                        {/* <div className="text-center mt-4">
                                            <div className="p-progress-waiting">
                                                <div className="p-progress-percent" style={{ width: '60%' }} />
                                            </div>
                                            <h4 className="mb-2 pb-1 mt-5">Waiting for the transaction to complete</h4>
                                            <p>Please wait for the transaction to confirm before proceeding.</p>
                                            <p><a className="p-address" href={`${BSC_EXPLORER[chainId]}/address/${account}`} target="blank">{account}</a></p>
                                        </div> */}
                                    </div>
                                }

                                {swapCurrentStep === 4 &&
                                    <div className="bs-stepper-content" id="SwapStep4">
                                        <div className="text-center">
                                            <h4 className="mb-2 pb-1 pd-step-title">Confirm</h4>
                                            <p className="step-desc">Required transaction 2 of 2</p>
                                            <p className="step-desc">
                                                In this step, you deposit the tokens into the staking contract.<br />
                                                After this step, your tokens will be successfully staked.
                                            </p>
                                        </div>
                                    </div>
                                }

                                {swapCurrentStep === 5 &&
                                    <div className="bs-stepper-content" id="SwapStep5">
                                        <div className="text-center pb-5">
                                            <div className="icon-gradient mt-4">
                                                <i className="mdi mdi-shield-check-outline" style={{ fontSize: '70px', lineHeight: 1 }} />
                                            </div>
                                            <h4 className="pd-step-title pt-0">Success</h4>
                                            <p className="step-desc">
                                                Congratulations! Your tokens are now staked.<br />
                                                If desired, you may check Binance Smart Chain to confirm the transaction.
                                            </p>
                                            <p><a className="p-address" href={`${BSC_EXPLORER[chainId]}/tx/${transactionHash}`} target="blank">{transactionHash}</a></p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-5 text-center pd-step-buttons" style={{ height: '100px', paddingTop: '50px' }}>
                    {
                        !isSubmitOK ?
                            <div className="d-flex align-items-center justify-content-center" style={{ gap: '20px' }}>
                                <button onClick={() => swapBack()} type="button" disabled={!enableSwapBtn || swapCurrentStep === 1} className="btn-pd">
                                    <span><i className="mdi mdi-arrow-left me-2" />Previous</span>
                                </button>
                                <button onClick={() => swapNext()} type="button" disabled={!enableSwapBtn || swapCurrentStep === 5} className="btn-pd">
                                    <span>Next<i className="mdi mdi-arrow-right ms-2" /></span>
                                </button>
                            </div>
                            :
                            <div className="d-flex align-items-center justify-content-center">
                                <button onClick={() => submitDone()} type="button" className="btn-pd">
                                    <span>Done</span>
                                </button>
                            </div>
                    }
                </div>
            </div>
        </StakingPortalPage>
    );
};

export default StakingPage;
