import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery';
import { get } from "lodash";
import { Link } from "react-router-dom";
import { BSC_EXPLORER, MODE } from "../../_configs";
import { ACTION_CONST, ROUTES, ACTION_STATUS } from "../../constants";
import { helpers } from "../../shared/utils";
import StakingPortalPage from "../../shared/layout/StakingPortal";
import { useBnbBalance } from "../../shared/components/hook/useSate";

const WithDrawPage = () => {
    const dispatch = useDispatch();
    const [withdrawCurrentStep, setWithdrawCurrentStep] = useState(1);
    const isConnectWallet = useSelector((state) => get(state, "utils.isConnectWallet", false));
    const walletUtils = useSelector((state) => get(state, "utils.walletUtils", null));
    const walletAddress = useSelector((state) => get(state, "utils.walletAddress", ""));
    const stakingInfo = useSelector((state) => get(state, "wallet.stakingInfo", {}));
    const stakingWalletInfo = useSelector((state) => get(state, "wallet.stakingWalletInfo", {}));
    const [acceptTerm, setAcceptTerm] = useState(false);
    const [enableWithDrawBtn, setEnableWithDrawBBtn] = useState(false);
    const [transactionHash, setTransactionHash] = useState("");

    const [isSubmitOK, setIsSubmitOK] = useState(false);
    const bnbBalance = useBnbBalance()
    /* WITHDRAW */
    useEffect(() => {
        setWithdrawStepActive();
    }, [setWithdrawStepActive, withdrawCurrentStep])




    //enable withdraw button
    useEffect(() => {
        if (acceptTerm && isConnectWallet && bnbBalance > 0 && !stakingInfo["isStakingPaused"] && stakingWalletInfo["withdrawTimestamp"] > 0 && stakingWalletInfo["withdrawTimestamp"] <= (new Date().getTime())) {
            setEnableWithDrawBBtn(true);
        } else {
            setEnableWithDrawBBtn(false);
        }
    }, [acceptTerm, isConnectWallet, bnbBalance, stakingInfo, stakingWalletInfo])


    const withdrawBack = () => {
        if (withdrawCurrentStep == 1) {
            return;
        } else {
            setWithdrawCurrentStep(withdrawCurrentStep - 1);
        }
    }

    const withdrawNext = () => {

        if (withdrawCurrentStep === 2) {
            if (walletUtils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                })
                walletUtils.stakingExecuteWithdrawal(data => {
                    if (data.status === ACTION_STATUS.STAKING_EXECUTE_WITHDRAWAL_SUCCESS) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        setIsSubmitOK(true)
                        setTransactionHash(data.txID)
                        setWithdrawCurrentStep(3);
                    }
  
                    if (data.status === ACTION_STATUS.STAKING_EXECUTE_WITHDRAWAL_FAIL) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })

                        dispatch({
                            type: ACTION_CONST.ALERT_FAILS,
                            message: "Withdraw stake fail"
                        })
                    }
                })
            }
        } else {
            if (withdrawCurrentStep == 3) {
                return;
            } else {
                setWithdrawCurrentStep(withdrawCurrentStep + 1);
            }
        }
    }


    const submitDone = () => {
        setWithdrawCurrentStep(1);
        setIsSubmitOK(false);
    }


    function setWithdrawStepActive() {
        $('#withdraw .bs-stepper-header .step').removeClass('active');
        $('#withdraw .bs-stepper-header .line').removeClass('active');
        for (var i = 1; i <= 3; i++) {
            if (i <= withdrawCurrentStep) {
                $('#withdraw #withdrawButtonStep' + i).addClass('active');
                $('#withdraw #withdrawLineStep' + (i - 1)).addClass('active');
            }
        }
        $('#withdraw .bs-stepper-content').hide();
        $('#withdraw #WithdrawStep' + withdrawCurrentStep).show();
    }

    return (
        <StakingPortalPage selectedTab={3}>
            <div className="tab-pane fade show active" id="withdraw" role="tabpanel" aria-labelledby="withdraw-tab">
                <div className="row justify-content-center mb-4">
                    <div className="col-lg-12">
                        <div className="bs-stepper w-100">
                            <div className="bs-stepper-header" role="tablist">
                                <div className={`step ${withdrawCurrentStep >= 1 ? 'active' : ''}`} id="withdrawButtonStep1">
                                    <button type="button" className="step-trigger ps-0">
                                        <img src={withdrawCurrentStep >= 1 ? '/images/check-active.png' : '/images/check.png'} height="25" />
                                        <span className="bs-stepper-label first">Checkpoints</span>
                                    </button>
                                </div>
                                <div className={`line ${withdrawCurrentStep >= 2 ? 'active' : ''}`} id="withdrawLineStep1" />
                                <div className={`step ${withdrawCurrentStep >= 2 ? 'active' : ''}`} id="withdrawButtonStep2">
                                    <button type="button" className="step-trigger">
                                        <img src={withdrawCurrentStep >= 2 ? '/images/check-active.png' : '/images/check.png'} height="25" />
                                        <span className="bs-stepper-label">Initialize Withdrawal</span>
                                    </button>
                                </div>
                                <div className={`line ${withdrawCurrentStep >= 3 ? 'active' : ''}`} id="withdrawLineStep2" />
                                <div className={`step ${withdrawCurrentStep >= 3 ? 'active' : ''}`} id="withdrawButtonStep3">
                                    <button type="button" className="step-trigger">
                                        <img src={withdrawCurrentStep >= 3 ? '/images/check-active.png' : '/images/check.png'} height="25" />
                                        <span className="bs-stepper-label">Confirmation</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="">
                            <div className="p-sidebar-card-body">
                                <div className="bs-stepper-content" id="WithdrawStep1">
                                    <div className="text-center mb-5">
                                        <h4 className="mb-2 pb-1 pd-step-title">Checkpoints</h4>
                                        <p className="step-desc">The following conditions must be met to proceed:</p>
                                    </div>

                                    <div className="grant__bordered bordered mb-0">
                                        <div className="row pd-card-steps">
                                            <div className="col-xl-3 col-md-6">
                                                <div className={`pd-card-step ${isConnectWallet ? 'selected' : ''}`} >
                                                    <div className="pd-card-step-title">Connected MetaMask</div>
                                                    <div className="pd-card-step-body mt-2">
                                                        If not connected, click the "Connect Wallet" button in the top right corner
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-3 col-md-6">
                                                <div className={`pd-card-step ${stakingWalletInfo["withdrawTimestamp"] > 0 && stakingWalletInfo["withdrawTimestamp"] <= (new Date().getTime()) ? 'selected' : ''}`} >
                                                    <div className="pd-card-step-title">7 day waiting period elapsed</div>
                                                    <div className="pd-card-step-body mt-2">

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-3 col-md-6">
                                                <div className={`pd-card-step ${bnbBalance > 0 ? 'selected' : ''}`} >
                                                    <div className="pd-card-step-title">BNB available</div>
                                                    <div className="pd-card-step-body mt-2">
                                                        BNB is required to pay transaction fees on the Binance Smart Chain network.<br />
                                                        BNB Balance: {helpers.formatNumberDownRoundWithExtractMax(bnbBalance, 4)}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-3 col-md-6">
                                                <div className={`pd-card-step ${stakingWalletInfo["withdrawTimestamp"] > 0 ? 'selected' : ''}`} >
                                                    <div className="pd-card-step-title">You have Unstaked your $METAV</div>
                                                    <div className="pd-card-step-body mt-2">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-4 mb-3 text-center">
                                        <div className="form-check">
                                            <input className="form-check-input float-none me-1"
                                                type="checkbox" defaultValue id="flexCheckDefault"
                                                onChange={() => setAcceptTerm(!acceptTerm)} />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                &nbsp;I have read the&nbsp;
                                                <Link target="_blank" to={ROUTES.TERM_OF_USES}>Terms and Conditions </Link>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="bs-stepper-content" id="WithdrawStep2">
                                    <div className="text-center">
                                        <h4 className="mb-2 pb-1 pd-step-title">Confirm Withdrawal</h4>
                                        <p className="step-desc">In this step, you complete the transaction that withdraws your $METAV tokens</p>
                                    </div>
                                </div>
                                <div className="bs-stepper-content" id="WithdrawStep3">
                                    <div className="text-center pb-5">
                                        <div className="icon-gradient mt-4">
                                            <i className="mdi mdi-shield-check-outline" style={{ fontSize: '70px', lineHeight: 1 }} />
                                        </div>
                                        <h4 className="pd-step-title pt-0">Confirmed</h4>
                                        <p className="step-desc">
                                            You have withdrawn your $METAV tokens.<br />
                                            If desired, you may check Binance Smart Chain to confirm the transaction.
                                        </p>
                                        <p><a className="p-address" href={`${BSC_EXPLORER[MODE]}/tx/${transactionHash}`} target="blank">{transactionHash}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-3 mt-4 text-center pd-step-buttons">
                    {
                        !isSubmitOK ?
                            <div className="d-flex align-items-center justify-content-center" style={{ gap: '20px' }}>
                                <button onClick={() => withdrawBack()} type="button" disabled={!enableWithDrawBtn || withdrawCurrentStep === 1} className="btn-pd">
                                    <span><i className="mdi mdi-arrow-left me-2" />Previous</span>
                                </button>
                                <button onClick={() => withdrawNext()} type="button" disabled={!enableWithDrawBtn || withdrawCurrentStep === 3} className="btn-pd">
                                    <span>Next<i className="mdi mdi-arrow-right ms-2" /></span>
                                </button>
                            </div>
                            :
                            <div className="d-flex align-items-center justify-content-center">
                                <button onClick={() => submitDone()} type="button" className="btn-pd">
                                    <span>Done</span>
                                </button>
                            </div>
                    }
                </div>
            </div>
        </StakingPortalPage>
    );
};

export default WithDrawPage;
