import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { isMobile } from "web3modal";
import exactMath from 'exact-math';
import { helpers } from "../../shared/utils";
import $ from 'jquery';
import { usePadTokenBalance, useWeb3Utils } from "./hook/useSate";
import { useKycStatus, useLatestBlockNumber, useStakeAmount } from "../../hook/useState";
import { useActiveWeb3React } from "../../hook";
import { getKYC } from "../../redux/services/kyc.api";
import { showKYC } from "../../_configs";
import { ACTION_CONST, ROUTES } from "../../constants";

const Header = () => {
  const dispatch = useDispatch();
  const { account } = useActiveWeb3React()
  const walletUtils = useWeb3Utils()
  const bscpadBalance = usePadTokenBalance();
  const latestBlock = useLatestBlockNumber()
  const stakedAmount = useStakeAmount()
  const kycStatus = useKycStatus();

  //set balance
  useEffect(() => {
    if (walletUtils && account) {
      //get bnb balance
      walletUtils.web3.eth.getBalance(account).then(balance => {
        dispatch({
          type: ACTION_CONST.GET_BNB_BALANCE,
          data: exactMath.div(balance, 10 ** 18)
        })
      }).catch(err => {
        console.log(err);
      })

      walletUtils.getTokenPadBalance().then(data => {

        dispatch({
          type: ACTION_CONST.GET_METAV_WALLET_BALANCE,
          data: data
        })
      })

      //get staking wallet Info
      walletUtils.getStakingInfoWallet().then(data => {
        dispatch({ type: ACTION_CONST.GET_STAKING_WALLET_INFO, data: data })
      });
      //Set interval
    }

  }, [latestBlock, walletUtils, account]);


  // //check show hide status
  useEffect(() => {
    if (!account) {
      return;
    }
    if ((Number(bscpadBalance) + Number(stakedAmount)) >= Number(showKYC)) {
      getKYCAddress(account)
    }
  }, [account, latestBlock])


  //get kyc
  const getKYCAddress = (address) => {
    getKYC(address, 'state').then(response => {
      address = address.toLowerCase()
      if (response) {
        const state = response.state;
        if (state === 1) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'START'
          })
        }
        if (state === 2) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'PENDING'
          })
        }
        if (state === 3) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'APPROVED'
          })
        }
        if (state === 4) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'ERROR'
          })
        }
      }

    }).catch(err => {
      console.log(err);
    })
  }

  const handleOnclickKyc = () => {
    getKYC(account, 'url').then(data => {
      if (data) {
        const url = data.url
        window.open(url, "_blank")
      }

    }).catch(err => {
      console.log(err);
    })
  }

  const handleTabChange = () => {
    $('.navbar-toggler').trigger('click');
  }

  return (
    <>
      <nav id="PPNavbar" className="navbar navbar-expand-lg navbar-dark">
        <div className="container">
          <a className="navbar-brand" href="https://metavpad.com" target="_blank">
            <img src="/images/logo.png" alt="MetaVPad" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarApp"
            aria-controls="navbarApp" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarApp">
            <ul className="navbar-nav me-auto mb-3 mt-3 mt-lg-0 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to={ROUTES.PROJECTS} onClick={handleTabChange}>Projects</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to={ROUTES.STACKING} onClick={handleTabChange}>Stake</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to={ROUTES.UNSTACKING} onClick={handleTabChange}>Unstake</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to={ROUTES.WITHDRAW} onClick={handleTabChange}>Withdraw</NavLink>
              </li>
            </ul>
            <ul className={account ? 'navbar-nav mb-2 mb-md-0 connected' : 'navbar-nav mb-2 mb-md-0 not-connected'}>
              {
                !account ?
                  <li className="nav-item me-2">
                    <div className="d-flex align-items-center justify-content-end">
                      <a href="#" data-bs-toggle="modal" data-bs-target={"#connectWalletModal"} className="btn-pd">
                        <span>Connect Wallet</span>
                      </a>
                    </div>
                  </li>
                  :
                  <>
                    <li className="nav-item me-2">
                      <a href="#" data-bs-toggle="modal" data-bs-target={"#walletModal"} className="btn-pd">
                        <span><b>{helpers.formatTransactionHash(account, 4, 4)}</b> - {helpers.formatNumberDownRoundWithExtractMax(bscpadBalance, 4)} $METAV</span>
                      </a>
                    </li>
                    {
                      kycStatus === 'START' &&
                      <li className="nav-item">
                        <button className="btn-pd btn-kyc-start px-4 ms-2" onClick={() => handleOnclickKyc()}>
                          <i className="mdi mdi-pencil-plus me-1" style={{ fontSize: '24px', lineHeight: 1 }}></i>
                          <span >KYC</span>
                        </button>
                      </li>
                    }
                    {
                      kycStatus === 'PENDING' &&
                      <li className="nav-item">
                        <button className="btn-pd btn-kyc-pending px-4 ms-2" onClick={() => handleOnclickKyc()}>
                          <i className="mdi mdi-exclamation-thick me-1" style={{ fontSize: '24px', lineHeight: 1 }}></i>
                          <span>KYC</span>
                        </button>
                      </li>
                    }
                    {
                      kycStatus === 'APPROVED' &&
                      <li className="nav-item">
                        <button className="btn-pd btn-kyc-success px-4 ms-2">
                          <i className="mdi mdi-check-bold me-1" style={{ fontSize: '24px', lineHeight: 1 }}></i>
                          <span>KYC</span>
                        </button>
                      </li>
                    }
                    {
                      kycStatus === 'ERROR' &&
                      <li className="nav-item">
                        <button className="btn-pd btn-kyc-failed px-4 ms-2" onClick={() => handleOnclickKyc()}>
                          <i className="mdi mdi-alert-octagon-outline me-1" style={{ fontSize: '24px', lineHeight: 1 }}></i>
                          <span>KYC</span>
                        </button>
                      </li>
                    }
                  </>
              }
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
