import React from "react";
import { Link } from "react-router-dom";
import { useOpeningProjects } from "../../../hook/useState";

const OpeningProjectsComponent = () => {
  const openingProjects = useOpeningProjects();

  return (
    <>
      <div className="pd-projects pd-projects-open">
        <div className="container">
          <h2 className="pd-title-section">
            <span>Projects Open Now</span>
          </h2>
          <div className="text-center">
            <div className="investment-section-items">
              <div className="row gx-md-5 justify-content-xl-center">
                {openingProjects.length > 0 ?
                  openingProjects.map((item, key) => {
                    return (
                      <div className="col-xl-4 col-lg-6 col-md-6 text-center" key={key}>
                        <div className="pd-project-item">
                          <div className="pd-project-header d-flex align-items-center justify-content-between">
                            <span className="pd-project-status open">Open</span>
                            <span className="pd-project-symbol">{item.symbol}</span>
                          </div>

                          <div className="pd-project-inner">
                            <div className="pd-project-summary">
                              <Link to={`projects/${item.contract}`} className="text-decoration-none w-100" >
                                <div className="pd-project-logo mb-3">
                                  <img src={item.logo} alt="" />
                                  {
                                    item.athMultiplier &&
                                    <div className="pp-top-tag">
                                      <div><small>ATH</small></div>
                                      <div><b>{item.athMultiplier}x</b></div>
                                    </div>
                                  }
                                </div>
                                <div className="pd-project-name text-center">{item.name}</div>
                              </Link>
                              <div className="pd-project-description text-center mb-4">
                                {item.description}
                              </div>
                            </div>

                            <div className="pd-project-detail">
                              <ul className="mb-3">
                                <li>
                                  <span>Swap rate</span><br />
                                  <b className="nowrap text-white">
                                    1 {item.symbol} = {(item["rate"])} {item["projectTokenSymbol"]}
                                  </b>
                                </li>
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                      <span>Cap</span><br />
                                      <b className="nowrap text-white">
                                        {`${item.maxTotalParticipationAllocated || 0} ${item.symbol || ""}`}
                                      </b>
                                    </div>
                                    <div className="pe-3">
                                      <span>Access</span><br />
                                      <b className="nowrap text-white">
                                        {item.isPrivate ? "Private" : "Public"}
                                      </b>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  {
                                    item.state == 'O' ?
                                      <div className={item.state == 'O' ? 'pp-card-progress-wrap light-progress disabled' : 'pp-card-progress-wrap'}>
                                        <div className="mb-2 d-flex justify-content-between align-items-center pp-card-progress-top">
                                          <div className="pp-card-col">Progress</div>
                                          {item.state != 'O' && <div className="pp-card-col pp-card-col-participants text-end">Participants <b className="text-participants font-12">{item['totalCountUserParticipated']}</b></div>}
                                        </div>
                                        <div className='pp-card-progress'>
                                          <div className="pp-card-progress-percent" style={{ width: `${(item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100}%` }}></div>
                                          <div className="pp-card-progress-label">

                                            <span className="participants-center" >Allocation round</span>
                                            <span className="participants-center" style={{ top: "8px" }}><b>{item['totalCountUserParticipated']}</b> Participants</span>
                                          </div>
                                        </div>
                                      </div>
                                      :
                                      <>
                                        <div className={'pp-card-progress-wrap'}>
                                          <div className="mb-2 d-flex justify-content-between align-items-center pp-card-progress-top">
                                            <div className="pp-card-col">Progress</div>
                                            <div className="pp-card-col pp-card-col-participants text-end">Participants <b className="text-participants font-12">{item['totalCountUserParticipated']}</b></div>
                                          </div>
                                          <div className='pp-card-progress'>
                                            <div className="pp-card-progress-percent" style={{ width: `${(item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100}%` }}></div>
                                            <div className="pp-card-progress-label">
                                              <span><b>{((item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100).toFixed(2)}%</b></span>
                                              {item.state == 'O' && <span className="participants-center" style={{ top: "8px" }}><b>{item['totalCountUserParticipated']}</b> Participants</span>}
                                              <span className="text-allocation"><b>{item.totalFundParticipated.toFixed(4)}/{item.maxTotalParticipationAllocated}</b></span>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                  }
                                </li>
                              </ul>
                            </div>

                            {
                              item.button_name && <div className="pd-project-cta text-center">
                                <a href={item.button_url} target="blank" className="btn-pd py-3 h-auto">
                                  <span>{item.button_name}</span>
                                </a>
                              </div>
                            }
                          </div>

                          <div className="pd-project-socials">
                            {
                              item.pancakeswap &&
                              <a style={{ backgroundColor: '#47d4dc' }} href={item.pancakeswap} target="_blank"><img height="18" src="/images/pancake-swap.png" /></a>
                            }
                            {
                              item.website &&
                              <a href={item.website} target="_blank"><i className="fas fa-globe"></i></a>
                            }
                            {
                              item.twitter &&
                              <a href={item.twitter} target="_blank"><i className="fab fa-twitter"></i></a>
                            }
                            {
                              item.medium &&
                              <a href={item.medium} target="_blank"><i className="fab fa-medium-m"></i></a>
                            }
                            {
                              item.telegram &&
                              <a href={item.telegram} target="_blank"><i className="fab fa-telegram"></i></a>
                            }
                          </div>

                          <div className="pd-project-item-footer"></div>
                        </div>
                      </div>
                    )
                  })
                  :
                  <span className="text-white fs-mb-16" style={{ fontSize: '24px' }}>
                    No projects currently open
                  </span>
                }
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default OpeningProjectsComponent;
