import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useActiveWeb3React } from '../../hook';
import useAuth from '../../hook/useAuth';
import Web3Helper from '../utils/walletExtensionUtils';
import { connectorLocalStorageKey } from '../../components/literals';
import { ACTION_CONST, connectors } from '../../constants';

const ConnectWalletModal = () => {
    const dispatch = useDispatch();
    const { login } = useAuth();
    const { account, library, error, chainId } = useActiveWeb3React()
    const handleConnectClick = useCallback((walletConfig) => {
        try {
            login(walletConfig.connectorId);
            window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId);
        } catch (error) {
            console.log(error);
        }
    }, [])

    useEffect(() => {
        if (error)
            return;
        if (account && library && library.provider) {
            const web3 = new Web3Helper(library.provider, account, chainId)
            dispatch({
                type: ACTION_CONST.ENABLE_WALLET_SUCCESS,
                data: web3
            })
            return;
        }
    }, [library, account, error])

    return (
        <>
            <div className="modal fade" id="connectWalletModal" tabIndex="-1" aria-labelledby="connectWalletModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="connectWalletModalLabel">Connect to wallet</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                        {connectors.map((entry, key) => {
                                if (entry.enable === true) {
                                    return (
                                        <button type="button" className={`btn-pd btn-connect w-100 ${key ==0 ? '' : 'mt-3'}`} key ={key}data-bs-dismiss="modal" onClick={() => 
                                            handleConnectClick(entry)
                                        }>
                                            <img className="btn__icon me-2" src={entry.icon} height="26" alt={entry.connectorId}  />
                                            <span>{entry.title}</span>
                                        </button>
                                    )
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ConnectWalletModal;


