import React from "react";
import moment from 'moment';

const ScheduleTabComponent = (props) => {
  return (
    <div className="pd-p-info">
      <div className="pd-allocation-title">
        <h4 className="pd-title-section info text-start fs-32 mb-4">Schedule</h4>
      </div>
      <div className="table-responsive">
        <table className="table mb-0 pp-table-info text-white">
          <tbody>
            <tr className="card-header" style={{ border: "none" }}>
              <td>Round</td>
              <td>Opens</td>
              <td>Closes</td>
            </tr>
            {props.roundInfo.length > 0 &&
              props.roundInfo.map((item, key) =>
              (
                <tr key={key}>
                  <td>{item.round}</td>
                  <td>
                    <h5 className="mb-0">{moment(item.opens * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</h5>
                  </td>
                  <td>
                    <h5 className="mb-0">{moment(item.closes * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</h5>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScheduleTabComponent;
