import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getStakingContractInfo } from "../utils/bscHelpers";
import RightBar from "./RightBar";
import { changeTab } from "../../redux/action/app";
import { useSubmit, useStakingInfo } from "../components/hook/useSate";
import { helpers } from "../utils";
import { get } from "lodash";
import { useLatestBlockNumber } from "../../hook/useState";
import { ACTION_CONST, ROUTES } from "../../constants";
import { Link } from "react-router-dom";

const StakingPortalPage = ({ selectedTab, children }) => {
    const dispatch = useDispatch();
    const stakingInfo = useStakingInfo();
    const latestBlock = useLatestBlockNumber();
    const stakerCount = get(stakingInfo, 'stakerCount', 0);
    const apyPercentage = get(stakingInfo, 'apyPercentage', 0);
    const totalStakedAmount = get(stakingInfo, 'totalStakedAmount', 0);
    const submitOK = useSubmit()

    useEffect(() => {
        dispatch(changeTab(1));
    }, [])

    useEffect(() => {
        getStakingContractInfo().then(data => {
            dispatch({ type: ACTION_CONST.GET_STAKING_INFO, data: data });
        })
    }, [dispatch, submitOK, latestBlock]);

    return (
        <>
            <div className="pd-welcome">
                {
                    stakingInfo["isStakingPaused"] ? <div className="mb-5" style={{ marginTop: '-50px' }}>
                        <div className="container text-center">
                            <div className="row justify-content-center">
                                <div className="col-xl-7 col-lg-9">
                                    <div className="px-3 py-2" style={{ borderRadius: '20px', backgroundColor: '#df3c66' }}>
                                        <span className="pp-banner-top">
                                            <i className="mdi mdi-alert-outline fs-20 me-2"></i> All staking functions are temporarily paused. Please check back again later.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : <div></div>
                }
                <div className="container">
                    <div className="">
                        <h1>METAVPAD</h1>
                        {selectedTab == 1 && <h4 className="p-tab-title mb-0">Stake your <span>$METAV</span></h4>}
                        {selectedTab == 2 && <h4 className="p-tab-title mb-0">Unstake your <span>$METAV</span></h4>}
                        {selectedTab == 3 && <h4 className="p-tab-title mb-0">Withdraw your <span>$METAV</span></h4>}
                        <div className="mt-5 text-center">
                            <a href="https://metavpad.com/" className="btn-pd me-3" target="_blank">Homepage</a>
                            <Link to={ROUTES.PROJECTS} className="btn-pd">Launchpad</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="p-content-tabs">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="pd-card pd-card-1 highlight1">
                                        <div className="pd-card-title">Number of Stakers</div>
                                        <div className="pd-card-body">
                                            <span className="pd-card-value">{stakerCount}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="pd-card pd-card-2">
                                        <div className="pd-card-title">Total <span>$METAV</span> Staked</div>
                                        <div className="pd-card-body">
                                            <span className="pd-card-value">{helpers.formatNumberDownRound(totalStakedAmount, 2)}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="pd-card pd-card-3">
                                        <div className="pd-card-title">APY</div>
                                        <div className="pd-card-body">
                                            <span className="pd-card-value">{helpers.formatNumberDownRound((apyPercentage / 100).toString(), 2)}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <RightBar />
                        </div>
                    </div>
                    <div className="tab-content pt-5 mt-5" id="myTabContent">
                        {children}
                    </div>
                </div>
            </div>

            {/* <TransactionConfirmationModal
                isOpen={showModal}
                attemptingTxn="########"
                hash={undefined}
                pendingText="Loading..."
                children={undefined}
                onDismiss={() => setShowModal(false)}
            /> */}
        </>
    );
};

export default StakingPortalPage;
