import React from 'react';
import { Link } from 'react-router-dom';
import packageJson from '../../../package.json';
import { ROUTES } from '../../constants';
import ConnectWalletModal from "../modals/ConnectWalletModal";
import WalletModal from "../modals/WalletModal";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="pp-footer pb-5">
        <div className="container">
          <div className='pp-footer-socials d-none mb-5'>
            <h2>METAVPAD SOCIALS</h2>
            <div className='row justify-content-center'>
              <div className='col-md-3'>
                <div className='pp-footer-social'>
                  <div>Twitter</div>
                  <a href='https://twitter.com/MetaVPad' target="_blank">
                    <img src='/images/website-icon.svg' />
                    <span>TWITTER.COM/METAVPAD</span>
                  </a>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='pp-footer-social'>
                  <div>Telegram Announcement</div>
                  <a href='https://t.me/metavpadann' target="_blank">
                    <img src='/images/website-icon.svg' />
                    <span>T.ME/METAVPADANN</span>
                  </a>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='pp-footer-social'>
                  <div>Medium</div>
                  <a href='https://medium.com/@metavpad/' target="_blank">
                    <img src='/images/website-icon.svg' />
                    <span>HTTPS://MEDIUM.COM/@METAVPAD/</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between pp-footer-content">
            <div>
              {/* <span>Copyright © {currentYear}. All Rights Reserved by MetaVPad</span> */}
              <img src='/images/logo.png' height={60} />
            </div>
            <div> v{packageJson.version}</div>
            <div className="text-end pp-footer-link">
              <Link target="_blank" to={ROUTES.PRIVACY_POLICY}>Privacy Policy </Link>
              <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <Link target="_blank" to={ROUTES.COOKIES_POLICY}>Cookies Policy </Link>
              <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <Link target="_blank" to={ROUTES.TERM_OF_USES}>Terms of Use </Link>
            </div>
          </div>
        </div>
      </div>
      <ConnectWalletModal />
      <WalletModal />
      {/* <TransactionConfirmationModal isOpen={openModal} onDismiss={() => setOpenModal(true)} attemptingTxn={true} pendingText="####_PENDING_TEXT" /> */}
    </>
  );
}