import React, { useEffect, useState } from "react";
import moment from 'moment'
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_CONST } from "../../../constants";
import { addTokenToMetamask } from '../../../shared/utils/metamaskUtils';
import { isMetamaskAvailable } from "../../../shared/utils/utils";
import { formatTimeStampAllocation, formatTokenAllocation, helpers } from "../../../shared/utils/helpers";
import { useWeb3Utils } from "../../../hook/useState";

const YourAllocationComponent = (props) => {
    const dispatch = useDispatch();
    const web3Utils = useWeb3Utils();
    const [layout, setLayout] = useState(1);
    const [allocationInfo, setAllocation] = useState([])

    useEffect(() => {
        if (props) {
            setAllocation(props.allocationInfo)
            setLayout(props.layout)
        }
    }, [props])

    // console.log("allocationInfo==>", allocationInfo);

    const handleClaimClick = (index) => {
        if (web3Utils) {
            dispatch({
                type: ACTION_CONST.REQUEST_SUBMIT
            })
            web3Utils.claim({
                contractAddress: props.contractAddress,
                index: index
            }, (data) => {
                if (data.status == "CLAIM_SUCCESS") {
                    dispatch({ type: ACTION_CONST.REQUEST_DONE })
                    dispatch({
                        type: ACTION_CONST.ALERT_SUCCESS,
                        message: "Tokens Successfully Claimed"
                    })
                    props.handleBuyClick()
                }
                if (data.status == "CLAIM_FAIL") {
                    dispatch({ type: ACTION_CONST.REQUEST_DONE })
                    dispatch({
                        type: ACTION_CONST.ALERT_FAILS,
                        message: "Failed to claim tokens, Please contact support"
                    })
                    props.handleBuyClick()
                }
                //code handle event claim
            })
        }
    }

    const handleAddTokenToMetamask = async () => {
        if (props.tokenAddress) {
            addTokenToMetamask({
                tokenAddress: props.tokenAddress,
                tokenSymbol: props.tokenSymbol,
                tokenDecimals: props.decimals,
                tokenImage: ""
            }, (res) => {
                if (res.status == "ADD_TOKEN_SUCCESS") {
                    dispatch({
                        type: ACTION_CONST.ALERT_SUCCESS,
                        message: "Successfully added token to MetaMask"
                    })
                }
                if (res.status == "ADD_TOKEN_FAILS") {
                    dispatch({
                        type: ACTION_CONST.ALERT_FAILS,
                        message: "Failed to add token to MetaMask"
                    })
                }
            })
        } else {
            dispatch({
                type: ACTION_CONST.ALERT_FAILS,
                message: "Token incorrect!"
            })
        }

    }

    return (
        <div className="py-3">
            <div className="d-flex flex-wrap justify-content-center justify-content-md-end" style={{ rowGap: '20px' }}>
                <div>
                    {
                        props.allocationInfo.length >= 0 &&
                        <button type="button" onClick={() => handleAddTokenToMetamask()} className="btn-pd h-auto py-3">
                            <span>Add token to <b>MetaMask</b></span>
                        </button>
                    }
                </div>
            </div>
            <div className="pd-allocation">
                <div className="pd-allocation-title">
                    <h4 className="pd-title-section text-start fs-32 mb-4">Your Allocation</h4>
                </div>
                <div className="d-lg-none d-block mt-4">
                    {
                        (allocationInfo.length > 0 && layout === 1) &&
                        allocationInfo.map((item, key) => (
                            <div className="allocation-card" key={key}>
                                <div>#{item.no}</div>
                                <div>
                                    <span>Allocation</span>
                                    <h4>{helpers.formatNumberDownRoundWithExtractMax((item.allocationAmount / 10 ** props.decimals || 0), 4)} {props.tokenSymbol}</h4>
                                </div>
                                <div>
                                    <span>Date</span>
                                    <h4>{(item.timestamp !== 0 && item.timestamp !== "0") ?
                                        <div>{moment(item.timestamp * 1000 || 0).utc().format('YYYY-MM-DD HH:mm:ss')} UTC </div>
                                        : "DEX Listing"
                                    }</h4>
                                </div>
                                <div>
                                    <span>Claimed</span>
                                    <h4>{helpers.formatNumberDownRoundWithExtractMax((item.claimedAmount / 10 ** props.decimals || 0), 4)} {props.tokenSymbol}</h4>
                                </div>
                                <div>
                                    <button type="button" disabled={!(item.status === "OPEN")} onClick={() => handleClaimClick(key)} className="btn-pd h-auto py-3 w-100" style={{ minWidth: '170px' }}>
                                        <span>Claim Tokens</span>
                                    </button>
                                </div>
                            </div>
                        ))
                    }
                    {
                        (allocationInfo.length > 0 && layout === 2) &&
                        allocationInfo.map((item, key) => (
                            <div className="allocation-card" key={key}>
                                <div>#{item.no}</div>
                                <div>
                                    <span>Allocation</span>
                                    <h4>{formatTokenAllocation(item.allocationAmount, props.decimals, 4)}</h4>
                                </div>
                                <div>
                                    <span>Percentage</span>
                                    <h4>{(item.percentage / 100).toFixed(2)}%</h4>
                                </div>
                                <div>
                                    <span>Date</span>
                                    <h4>{(item.timestamp !== 0 && item.timestamp !== "0") ?
                                        <div dangerouslySetInnerHTML={{ __html: formatTimeStampAllocation(item.timestamp) }}></div>
                                        : "DEX Listing"
                                    }</h4>
                                </div>
                                <div>
                                    <span>Claimed</span>
                                    <h4>{helpers.formatNumberDownRoundWithExtractMax((item.claimedAmount / 10 ** props.decimals || 0), 4)}</h4>
                                </div>
                                <div>
                                    <button type="button" disabled={!(item.status === "OPEN")} onClick={() => handleClaimClick(key)} className="btn-pd h-auto py-3 w-100" style={{ minWidth: '170px' }}>
                                        <span>Claim Tokens</span>
                                    </button>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="d-none d-lg-block">
                    <div className="table-responsive">
                        <table className="table pp-table-info text-white">
                            <tbody>
                                <tr className="card-header" style={{ border: "none" }}>
                                    <td>No.</td>
                                    <td>Allocation</td>
                                    {
                                        layout === 2 &&
                                        <td>Percentage</td>
                                    }
                                    <td>Date</td>
                                    <td>Claimed</td>
                                    {isMetamaskAvailable() &&
                                        <td className="text-center" style={{ width: '200px', maxWidth: '200px' }}>Action</td>
                                    }
                                </tr>
                                {
                                    (allocationInfo.length > 0 && layout === 1) &&
                                    allocationInfo.map((item, key) => (
                                        <tr key={key}>
                                            <td>
                                                <h5 className="mb-0">{item.no}</h5>
                                            </td>
                                            <td>
                                                <h5 className="mb-0">{helpers.formatNumberDownRoundWithExtractMax((item.allocationAmount / 10 ** props.decimals || 0), 4)} {props.tokenSymbol}</h5>
                                            </td>
                                            <td>
                                                <h5 className="mb-0">
                                                    {(item.timestamp !== 0 && item.timestamp !== "0") ?
                                                        <div>{moment(item.timestamp * 1000 || 0).utc().format('YYYY-MM-DD HH:mm:ss')} UTC </div>
                                                        : "DEX Listing"
                                                    }
                                                </h5>
                                            </td>
                                            <td>
                                                <h5 className="mb-0">{helpers.formatNumberDownRoundWithExtractMax((item.claimedAmount / 10 ** props.decimals || 0), 4)} {props.tokenSymbol}</h5>
                                            </td>
                                            <td className="text-center" style={{ maxWidth: '200px' }}>
                                                <button type="button" disabled={!(item.status === "OPEN")} onClick={() => handleClaimClick(key)} className="btn-pd h-auto py-3" style={{ minWidth: '120px' }}>
                                                    <span>Claim Tokens</span>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                                {
                                    (allocationInfo.length > 0 && layout === 2) &&
                                    allocationInfo.map((item, key) => (
                                        <tr key={key}>
                                            <td>
                                                <h5 className="mb-0">{item.no}</h5>
                                            </td>
                                            <td>
                                                <h5 className="mb-0">{formatTokenAllocation(item.allocationAmount, props.decimals, 4)}</h5>
                                            </td>
                                            <td>
                                                <h5 className="mb-0">{(item.percentage / 100).toFixed(2)}%</h5>
                                            </td>
                                            <td>
                                                <h5 className="mb-0">
                                                    {(item.timestamp !== 0 && item.timestamp !== "0") ?
                                                        <div dangerouslySetInnerHTML={{ __html: formatTimeStampAllocation(item.timestamp) }}></div>
                                                        : "DEX Listing"
                                                    }
                                                </h5>
                                            </td>
                                            <td>
                                                <h5 className="mb-0">{helpers.formatNumberDownRoundWithExtractMax((item.claimedAmount / 10 ** props.decimals || 0), 4)}</h5>
                                            </td>
                                            <td className="text-center" style={{ maxWidth: '200px' }}>
                                                <button type="button" disabled={!(item.status === "OPEN")} onClick={() => handleClaimClick(key)} className="btn-pd h-auto py-3" style={{ minWidth: '170px' }}>
                                                    <span>Claim Tokens</span>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default YourAllocationComponent;
